<style lang="sass" scoped>
.title
  font-weight: bold
  font-size: 1.5rem
  color: #555
</style>
<template lang="pug">
div
  .d-flex.justify-content-center.align-items-top(style='min-height: 300px')
    .col-10
      .mb-4
      h3.title.py-3 문자 발신자 설정

      form(@submit.prevent='submit')
        .mb-4
        .form-group
          label.d-block 등록된 번호: {{sms_config.numbers && sms_config.numbers.length}}건
          .border.p-3.rounded.shadow-sm(v-for='config in sms_config.numbers' :key='config.sms_sender_verify_log_id')
            h3.mb-0 {{config.text || config.number}}
              //- a.btn.btn-light.ml-3(href='#' @click='remove(config)') 삭제
            //- pre {{config.number}}
          .pb-4
        .form-group
          label.d-block 신규 번호 등록
          input.form-control(type='text' v-model='phone' placeholder='휴대폰번호, 유선전화번호를 입력해주세요.')
          span.text-muted.pl-2
            ul
              li 기본: 8자리 ~ 11자리 발신번호
              li 유선 전화번호: 지역 번호를 포함
              li 이동통신 전화번호
              li 대표 전화번호: 15xx, 16xx, 18xx(번호 앞에 지역번호 사용금지
        .form-group
          button.btn.btn-primary.mr-3(type='button' @click='request_sms()') 휴대폰 인증번호 요청
          button.btn.btn-secondary.mr-3(type='button' @click='request_ars()') 유선전화 인증ARS 요청

        .form-group.pt-4(v-show='code_requested')
          label.d-block 인증번호 입력
          input.form-control(type='text' v-model='code')
        .form-group(v-show='code_requested')
            button.btn.btn-primary(type='button' @click='request2()') 발신자번호 등록
        //- .form-group
          button.btn.btn-primary.float-right(type='submit') 저장




</template>

<script>

export default {
  name: 'manage-sms',
  props: ['property_id'],
  components: {

  },
  computed: {
    // session() {
    //   return this.$store.state.session
    // },
  },
  mounted() {
    this.load()
  },
  data() {
    return {
      loading: false,
      sms_config: {},
      phone: '',
      code_requested: false,
      pintype: '',
      code: '',
    }
  },
  methods: {
    async load() {
      try {
        const r = await this.$http.get(`/v1/property/${this.property_id}/sms_config`)
        this.sms_config = r.data.sms_config || {}
        if (!this.sms_config.numbers) this.sms_config.numbers = []
        this.loading = true
      } catch (error) {
        console.log({error})
      }
    },

    async request_sms() {
      try {
        this.pintype = 'sms'
        const r = await this.$http.post(`/v1/property/${this.property_id}/sms/verify_sender`, {
          phone: this.phone,
          pintype: this.pintype,
        })
        if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '오류')
        this.code_requested = true

      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async request_ars() {
      try {
        this.pintype = 'vms'
        const r = await this.$http.post(`/v1/property/${this.property_id}/sms/verify_sender`, {
          phone: this.phone,
          pintype: this.pintype,
        })
        if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '오류')
        this.code_requested = true

      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },

    async request2() {
      try {
        const r = await this.$http.post(`/v1/property/${this.property_id}/sms/verify_sender/next`, {
          phone: this.phone,
          code: this.code,
          pintype: this.pintype,
        })
        if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '오류')

        this.code_requested = false
        this.phone = ''
        this.code = ''
        this.pintype = ''

        this.$modal.show('dialog', {
          title: '알림',
          text: '등록되었습니다.',
        })

        this.load()

      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },

    open_noti() {
      alert(`알림 표시합니다: 문자답변, 이메일답변, 가져오기성공`)
    },

    remove(config) {
      alert(`미구현 ${JSON.stringify(config)}`)
    }

  },
}
</script>
